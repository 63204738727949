import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
import './works.scss';

const WorksPage = () => {
  const { allWordpressWpWorks } = useStaticQuery(
    graphql`
      {
        allWordpressWpWorks(sort: { fields: menu_order, order: ASC }) {
          edges {
            node {
              id
              slug
              acf {
                content_image {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return (
    <div className={'works'}>
      {allWordpressWpWorks.edges.map((work) => (
        <div key={work.node.id} className={'works__item'}>
          <Link to={`/works/${work.node.slug}`}>
            {work.node.acf.content_image?.localFile && (
              <Image
                fluid={
                  work.node.acf.content_image.localFile.childImageSharp.fluid
                }
                alt={'work'}
                objectFit={'cover'}
                objectPosition={'50% 50%'}
                className={'works__item--image'}
              />
            )}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default WorksPage;
