import React, { Fragment, useContext } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Layout from '../../components/layouts/layout';
import FullWidthLayout from '../../components/layouts/fullWidth';
import Exhibitions from '../../components/exhibitions/exhibitions';
import WorksPage from '../../components/works/works-page';
import './pages.scss';
import { PageDetailsContext } from '../../components/layouts/pagesWrapper';

export default ({ pageContext }) => {
  const { isClient } = useContext(PageDetailsContext);
  return (
    <Fragment>
      {pageContext.acf.full_content_width &&
        pageContext.acf.content_type === 'other' && (
          <FullWidthLayout>
            <div
              className={`page__fullwidth--content ${
                isClient && (isMobile || isTablet) ? 'mobile' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: pageContext.content }}
            />
          </FullWidthLayout>
        )}
      {!pageContext.acf.full_content_width &&
        pageContext.acf.content_type === 'other' && (
          <Layout header={pageContext.title}>
            <div
              className={`page__content ${
                isMobile || isTablet ? 'mobile' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: pageContext.content }}
            />
          </Layout>
        )}
      {pageContext.acf.content_type === 'exhibitions' && (
        <Layout header={pageContext.title}>
          <Exhibitions />
        </Layout>
      )}
      {pageContext.acf.content_type === 'works' && (
        <Layout header={pageContext.title}>
          <WorksPage />
          <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
        </Layout>
      )}
    </Fragment>
  );
};
