import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SimpleGallery from '../simple-gallery/simple-gallery';
import './exhibitions.scss';

const Exhibitions = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpExhibitions(
          sort: { order: DESC, fields: acf___exhibition_date }
        ) {
          edges {
            node {
              id
              title
              content
              acf {
                exhibition_date
                oembed
              }
              acf_photo_gallery {
                full_image_url {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(props) => (
      <div className={'exhibition'}>
        {props.allWordpressWpExhibitions.edges.map((exhibition) => (
          <div key={exhibition.node.id} className={'exhibition__content'}>
            <div
              dangerouslySetInnerHTML={{ __html: exhibition.node.content }}
            />
            {exhibition.node.acf_photo_gallery.length > 0 && (
              <SimpleGallery
                images={exhibition.node.acf_photo_gallery}
                alt={exhibition.node.title}
              />
            )}
              {exhibition.node.acf.oembed && (
                  <div className={'text-center mgtop30'}
                      dangerouslySetInnerHTML={{ __html: exhibition.node.acf.oembed }}
                  />
              )}
          </div>
        ))}
      </div>
    )}
  />
);
export default Exhibitions;
