import React, { Component, Fragment } from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import SwiperSlider from '../slider/swiper';
import { swiperContent } from '../../helpers/swiper-images';
import '@reach/dialog/styles.css';
import './simple-gallery.scss';

class SimpleGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryOpen: false,
      selectedImage: 0,
    };
  }

  handleGallery = (index) => {
    this.setState({
      selectedImage: index,
      galleryOpen: true,
    });
  };

  closeDialog = () => {
    this.setState({
      galleryOpen: false,
    });
  };

  render() {
    const { images, alt } = this.props;
    const { galleryOpen } = this.state;

    return (
      <Fragment>
        <div className={'simple-gallery'}>
          {images.map((image, index) => (
            <div
              key={image.full_image_url.localFile?.childImageSharp.fluid.src}
            >
              <button
                className={'simple-gallery__content-box'}
                type={'button'}
                onClick={() => this.handleGallery(index)}
              >
                <Image
                  fluid={image.full_image_url.localFile?.childImageSharp.fluid}
                  alt={alt}
                  objectFit='cover'
                  objectPosition='50% 50%'
                  className={'simple-gallery__content-box--image'}
                />
              </button>
            </div>
          ))}
        </div>
        {galleryOpen && (
          <SwiperSlider
            images={swiperContent(images)}
            index={this.state.selectedImage}
            onClose={() => this.closeDialog()}
          />
        )}
      </Fragment>
    );
  }
}

export default SimpleGallery;
